//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Accordian',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      active: false,
    };
  },
};
