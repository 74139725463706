//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, } from 'vuex';
import LocalStorageUtil from '../../utils/LocalStorageUtil';
import { setCustomDimensionSubscriptionType, setCustomDimensionCustomerType, } from '@/services/gtmManager';
import states from '@/data/states.json';
import professions from '@/data/profession.json';
export default {
  name: 'LoginForm',
  props: {
    impersonate: {
      type: Boolean,
      default: false,
    },
    showLoginModal: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      login: {
        username: '',
        password: '',
        impersonateId: this.$route.params.personId,
      },
      showError: {
        type: Boolean,
        default: false,
      },
      getCustomerType: '',
      loading: false,
      freeTrial: this.$route.query.freeTrial,
    };
  },
  beforeMount () {
    if (this.$auth.loggedIn && this.$route.query.redirect) {
      const redirect = this.$route.query.redirect;
      window.location.replace(redirect.toString());
    }
  },
  mounted () {
    this.loading = false;
  },
  methods: {
    ...mapActions('search', ['setFilter']),
    ...mapActions(['setImpersonate']),
    setProfessionStateFilters () {
      // if valid, set profession and state filters from account
      const stateForFilter = this.$auth.user.state.length > 0 ? this.findValidState(this.$auth.user.state) : false;
      if (stateForFilter) {
        this.setFilter({ ...stateForFilter, 'type': 'state' });
      }
      const professionForFilter = this.findValidProfession(this.$auth.user.title_code);
      if (professionForFilter) {
        this.setFilter({ ...professionForFilter, 'type': 'profession' });
      }
    },
    // returns valid object needed for state dropdown components
    findValidState (state) {
      const foundState = states.filter((s) => {
        return s ? s.id.includes(state) : null;
      });
      return foundState[0];
    },
    // this returns the valid object needed for profession dropdown components
    findValidProfession (profession) {
      const professionsArr = [];
      // instead of creating a new @Data json, here's an array without the keys from profession.json
      Object.entries(professions).forEach(p => professionsArr.push(p[1]));
      const foundProf = professionsArr.filter((p) => {
        return p ? profession && p.id.includes(profession) : null;
      });
      return foundProf[0];
    },
    async userLogin () {
      this.loading = true;
      try {
        LocalStorageUtil.set('login-step', 'pre-login');
        this.$auth.strategy.token?.reset();
        if (this.impersonate === true) {
          this.setImpersonate(true);
          const response = await this.$axios.$post('/login/impersonate', this.login);
          await this.$auth.setUserToken(response.token);
          const profile = response.profile;
          await this.$auth.setUser(profile);
          await this.$router.push('/account');
        } else if (this.$route.query.redirect) {
          const redirect = this.$route.query.redirect;
          await this.$auth.loginWith('local', { data: this.login })
            .then((response) => {
              const profile = response.data.profile;
              this.$auth.setUser(profile);
              window.location.replace(redirect.toString());
            });
          if (this.cartCount > 0) {
            await this.updateCart().catch((e) => {
              console.log('error updating cart after redirect - ' + e.message);
            });
            this.setProfessionStateFilters();
            LocalStorageUtil.set('login-step', 'logged-in');
          }
          await this.$router.push('/account');
        } else {
          await this.$auth.loginWith('local', { data: this.login })
            .then((response) => {
              const profile = response.data.profile;
              this.$auth.setUser(profile);
              if (['', null].includes(this.$auth.user.subscription_type)) {
                this.getCustomerType = 'Non Subscriber';
              } else if (this.$auth.user.subscription_type.toLowerCase().startsWith('expired.')) {
                this.getCustomerType = 'Expired Subscriber';
              } else {
                this.getCustomerType = 'Active Subscriber';
              }
              // if valid, set profession and state filters from account
              this.setProfessionStateFilters();
              LocalStorageUtil.set('login-step', 'logged-in');

              setCustomDimensionSubscriptionType(this.$gtm, this.$auth);
              setCustomDimensionCustomerType(this.$gtm, this.getCustomerType);
            });
          if (this.$route.path === '/login') {
            await this.$router.push('/account');
          } else {
            this.$emit('update:showLoginModal', false);
            if (this.$route.path === '/courses/free-course') {
              this.$emit('update:showFreeTrialModal', false);
            }
          }
        }
      } catch (err) {
        this.showError = true;
        this.loading = false;
        console.log(err);
        LocalStorageUtil.set('login-step', 'login-failed');
      }
    },

  },
};
